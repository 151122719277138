@import "~cropperjs/dist/cropper.css";

@import url('https://fonts.googleapis.com/css2?family=Allura&family=Amiko&family=Archivo&family=Give+You+Glory&family=Open+Sans&family=Zilla+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Dancing+Script&family=Merriweather&family=Roboto&family=Special+Elite&display=swap');

.cropper-view-box {
    outline: none;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}